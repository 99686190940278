// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Home from './pages/Home';
// import Header from './components/Header';
// import Footer from './components/Footer';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import Form from './pages/Form';


// const App = () => {
//   return (
//     <Router>
//       <Header />
//       <ToastContainer position="top-right" />

//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/contact" element={<Form />} />
//       </Routes>

//       <Footer />
//     </Router>
//   );
// };

// export default App;


import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Form from './pages/Form';

const App = () => {
  return (
    <Router>
      <Header />
      <ToastContainer position="top-right" />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Form />} />
      </Routes>

      <Footer />
    </Router>
  );
};

export default App;

