import React from 'react'

import logo from '../images/BLACK1.webp'

//icons

import { FaSquareXTwitter } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import { FiFacebook } from "react-icons/fi";
import { RiYoutubeLine } from "react-icons/ri";
import { TbBrandWhatsapp } from "react-icons/tb";
import { MdOutlinePhoneAndroid } from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { TbDeviceLandlinePhone } from "react-icons/tb";




const Footer = () => {
  return (
    <div>
      <div className="bg-[#CCCCCC] font-[poppins]">
        <div class=" rounded-t-lg drop-shadow-xl pt-4 sm:pt-10 lg:pt-12">

          <footer class="mx-auto w-[95%] md:w-[80%] px-4 md:px-8">

            <div className='flex flex-col md:flex-row justify-between '>

              <div className='p-4'>
                <img src={logo} className=' w-48 md:w-72 object-cover' alt="" />
                <div className='mt-3'>
                  <h3 className='font-bold text-[14px] md:text-[18px] ' >BigBay Cinema Factory Private Ltd</h3>
                </div>
                <div className='mt-4'>
                  <p className='font-semibold text-[12px] md:text-[14px] text-gray-900 md:w-[60%]'>No.271A, 3rd Floor, Maan Sarovar Tower, Scheme Road, Teynampet, Chennai, India</p>
                </div>
              </div>

              {/* <div className='p-4'>
                <h3 className='bg-gray-900 w-fit text-white uppercase px-3 py-3 font-bold rounded-md'>people with <span className=' bg-transparent text-red-700'>big dreams!</span></h3>
              </div> */}

              <div className='p-4'>

                <div className='flex flex-col gap-y-4 text-gray-900'>
                  <p className='text-[12px] md:text-[14px] font-semibold flex items-center gap-x-2 '><MdOutlinePhoneAndroid className='text-[20px] font-bold' /> +91 9884683888</p>
                  <p className='text-[12px] md:text-[14px] font-semibold flex items-center gap-x-2 '><MdOutlinePhoneAndroid className='text-[20px] font-bold' /> +91 9884428688</p>
                  <p className='text-[12px] md:text-[14px] font-semibold flex items-center gap-x-2 '><TbDeviceLandlinePhone className='text-[20px] font-bold' /> +(044) 24815365</p>
                  <a href="https://cinemafactory.co.in/" target='_blank'>
                    <p className='text-[12px] md:text-[14px] font-semibold flex items-center gap-x-2 '><BiWorld className='text-[20px] font-bold' />www.cinemafactory.co.in</p>
                  </a>
                </div>
              </div>


            </div>

            <div className='border border-gray-400'>
            </div>

            <div class=" py-4 md:py-6 text-center text-[11px] sm:text-[12px]  text-gray-900">© Vishnu Ads and Ventures 2024. All Rights Reserved.</div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Footer
