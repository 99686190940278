import React, { useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        state: '',
        district: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('https://script.google.com/macros/s/AKfycbxR2lIIsAZJIGGUyj0w8HeU-r6ZYCzBKHWUMJh4Ph8th_S1RxazY4hpx-47vMyllRG0mQ/exec', {
            method: 'POST',
            body: JSON.stringify(formData)
        });
        if (response.ok) {
            // alert('Form submitted successfully');
            toast.success("Email Send Successfully")
            setFormData({
                name: '',
                email: '',
                phone: '',
                state: '',
                district: '',
                message: ''
            });
        } else {
            // alert('Failed to submit the form');
            toast.error("Something Went Wrong")
        }
    };

    return (
        <div className="App">
            <div className="max-w-2xl mx-auto p-4 font-[poppins]">

                <div>
                    <h3 className='font-semibold text-[18px] sm:text-[20px] md:text-[24px] text-gray-50 mb-4 md:mb-8  tracking-[1px]'>Apply Now!</h3>
                </div>

                <form onSubmit={handleSubmit} className=" space-y-4 md:space-y-8 w-full">
                    <div className='flex flex-col md:flex-row items-center gap-x-5 gap-y-4 w-full'>
                        <div className='w-full'>
                            <label htmlFor="name" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-100">Name</label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                value={formData.name}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                required
                            // value={formData.name} onChange={handleChange}
                            />
                        </div>

                        <div className='w-full'>
                            <label htmlFor="email" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-100">Email</label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                value={formData.email}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                required
                            // value={formData.email} onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className='w-full'>
                        <label htmlFor="phone" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-100">Phone Number</label>
                        <input
                            id="phone"
                            name="phone"
                            type="text"
                            value={formData.phone}
                            onChange={handleChange}
                            className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            required
                        //   value={formData.phone} onChange={handleChange}
                        />
                    </div>

                    <div className='flex flex-col md:flex-row items-center gap-x-5 gap-y-3'>
                        <div className='w-full'>
                            <label htmlFor="state" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-100">State</label>
                            <input
                                id="state"
                                name="state"
                                type="text"
                                value={formData.state}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                required
                            // value={formData.state} onChange={handleChange}
                            />
                        </div>

                        <div className='w-full'>
                            <label htmlFor="district" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-100">District</label>
                            <input
                                id="district"
                                name="district"
                                type="text"
                                value={formData.district}
                                onChange={handleChange}
                                className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-100 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                required
                            // value={formData.district} onChange={handleChange}
                            />
                        </div>
                    </div>


                    <div className='mt-6 md:mt-4'>
                        <button
                            type="submit"
                            className="w-full text-[12px] sm:text-[14px] md:text-[17px] text-gray-900 bg-gray-100 hover:text-gray-100 hover:scale-105 duration-500 py-2 px-4 rounded-md font-semibold uppercase hover:bg-gray-800 hover:border hover:border-gray-200  focus:ring-2"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Form;



