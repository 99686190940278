import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import contact from '../images/banner/grad.png'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Form = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        country: '',
        state: '',
        district: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await emailjs.sendForm('service_e78y8xl', 'template_a6bgbwb', e.target, '0_6Hc4ZJ1GCBVo_6P');
            //   console.log('Form submitted successfully');
            toast.success("Email send Successfully")
            setFormData({
                name: '',
                email: '',
                phone: '',
                country: '',
                state: '',
                district: '',
                message: '',
            });
            // You can redirect or show a success message here
            e.target.reset(); // Clear the form inputs
        } catch (error) {
            //   console.error('Error submitting form data:', error);
            toast.error("Something Went Wrong")
            // Handle error, show error message, etc.
        }
    };

    return (
        <div className="App " >

            <div>
                {/* <img src={contact} className='w-full fixed top-0 -z-20' alt="" /> */}
            </div>

            <div className=' bg-gradient-to-l '>

                <div className="w-full md:w-[80%]  mx-auto p-4 font-[poppins] pt-8 pb-10 md:pt-20 md:pb-20 ">

                    <div className='flex flex-col md:flex-row items-center gap-x-8 gap-y-6' >

                        <div className='w-full md:w-[50%]'>

                            <img src={contact} className='object-cover w-[90%] ' alt="" />


                        </div>

                        <div className=' w-full md:w-[50%] mx-auto px-4 md:px-10'>

                            <div>
                                <h3 className='font-semibold text-[18px] sm:text-[20px] md:text-[24px] text-gray-900 mb-4 md:mb-8  tracking-[1px]'>Apply Now!</h3>
                            </div>


                            <form onSubmit={handleSubmit} className=" space-y-4 md:space-y-8 w-full">
                                <div className='flex flex-col md:flex-row items-center gap-x-5 gap-y-4 w-full'>
                                    <div className='w-full'>
                                        <label htmlFor="name" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-800">Name</label>
                                        <input
                                            type="text"
                                            id="name"
                                            name="user_name"
                                            className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            required
                                        // value={formData.name} onChange={handleChange}
                                        />
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="email" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-800">Email</label>
                                        <input
                                            type="email"
                                            id="email"
                                            name="user_email"
                                            className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            required
                                        // value={formData.email} onChange={handleChange}
                                        />
                                    </div>

                                </div>
                                <div className='w-full'>
                                    <label htmlFor="phone" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-800">Phone Number</label>
                                    <input
                                        type="tel"
                                        id="phone"
                                        name="user_phone"
                                        className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                        required
                                    //   value={formData.phone} onChange={handleChange}
                                    />
                                </div>

                                <div className='flex flex-col md:flex-row items-center gap-x-5 gap-y-3'>
                                    <div className='w-full'>
                                        <label htmlFor="state" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-800">State</label>
                                        <input
                                            type="text"
                                            id="state"
                                            name="user_state"
                                            className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            required
                                        // value={formData.state} onChange={handleChange}
                                        />
                                    </div>

                                    <div className='w-full'>
                                        <label htmlFor="district" className="block text-[12px] sm:text-[13px] md:text-[16px] font-medium text-gray-800">District</label>
                                        <input
                                            type="text"
                                            id="district"
                                            name="user_district"
                                            className="mt-1 block w-full px-4 py-2 border border-gray-300 bg-transparent text-gray-800 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                            required
                                        // value={formData.district} onChange={handleChange}
                                        />
                                    </div>
                                </div>


                                <div className='mt-6 md:mt-4'>
                                    <button
                                        type="submit"
                                        className="w-full text-[12px] sm:text-[14px] md:text-[17px] text-gray-900 bg-gray-100 hover:text-gray-100 hover:scale-105 duration-500 py-2 px-4 rounded-md font-semibold uppercase hover:bg-gray-800 hover:border hover:border-gray-200  focus:ring-2 focus:ring-indigo-500"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>





                </div>
            </div>
        </div>
    );
};

export default Form;



