import React from 'react'
import { useState, useEffect } from 'react';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { FaWhatsapp } from "react-icons/fa";
import { FiFacebook } from "react-icons/fi";
import { CiLinkedin } from "react-icons/ci";
import { ImPinterest } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import { VscDebugBreakpointData } from "react-icons/vsc";


// import logo from '../images/64.png'
import logo from '../images/BLACK1.webp'
import black from '../images/footer_logo-1.png'



const Header = () => {

    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);


    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };


    //color change
    const [navbarBg, setNavbarBg] = useState('bg-gray-100');


    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setNavbarBg('bg-gray-200 shadow-md');
            } else {
                setNavbarBg('bg-gray-100');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    function topPage() {
        window.scroll(0, 0)
    }

    //mobile view click the link hide the navbar

    const [hide, setHide] = useState(true)

    function clicklink() {
        setHide(false)
    }


    return (
        <>
            {/* ------ top navbar -------- */}

            <div class="inset-0  -z-10 h-full w-full bg-gray-900 bg-[radial-gradient(#000000_1px,transparent_1px)] [background-size:16px_16px] ">
                <div className='flex items-center   w-full'>


                    <div className='w-[45%] md:w-[20%] mx-auto flex justify-center bg-red-500 rounded-r-md text-white font-semibold uppercase px-2 md:px-0 py-1.5 md:py-2'>
                        <h3 className='flex items-center gap-x-2 text-[12px] md:text-[16px] animate-pulse hover:animate-none duration-700' >Admission Open <span class=" relative flex h-3 w-3">
                            <span class="animate-ping  absolute inline-flex h-full w-full rounded-full bg-white opacity-75"></span>
                            <span class="relative inline-flex rounded-full h-3 w-3 bg-white"></span>
                        </span>
                        </h3>
                    </div>

                    <div className=" marquee w-[55%] md:w-[80%] font-[roboto] py-1 md:py-2 ">

                        <div className="overflow-hidden whitespace-nowrap font-[roboto]">
                            <div className="animate-marquee text-[12px] md:text-[16px]  text-white font-semibold flex items-center  gap-x-4">
                               <span>1 Year Diploma Courses: Direction | Cinematography | Editing | VFX & CG. </span> 
                                <span className='text-[#ff0000] font-[poppins] font-bold'>Limited Seats Available!</span>
                                <span>Enroll Now!</span>
                            </div>
                        </div>

                        {/* Welcome to Cinema Factory Academy, Our current courses are Direction, Cinematography, Editing, VFX & CG */}


                        {/* <p className='text-white  tracking-[.04em] text-[8px] sm:text-[12px] md:text-[14px]'> Hire candidates from just 12/day. </p> */}
                    </div>
                </div>
            </div>


            {/* -------- second navbar ------- */}

            <nav className={` w-full sticky top-0 z-30  py-6 md:py-5 border-b pb-3 border-white ${navbarBg} `}>
                <div className="max-w-full mx-auto font-[Open Sans]  ">
                    {/* Logo */}
                    <div className='flex justify-between items-center px-4 sm:px-9 w-full xl:w-[95%] mx-auto'>


                        <div className='w-full'>
                            <Link to='/' onClick={topPage}>
                                <img src={logo} className=' w-36 sm:w-44 md:w-64 object-cover' alt="" />
                            </Link>
                        </div>


                        {/* Desktop Navigation */}
                        <ul className='hidden lg:flex w-full font-bold gap-x-4 xl:gap-x-8' >


                            <li className='  cursor-pointer duration-300 text-gray-800  text-[12px] xl:text-[14px] ' >
                                <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class="tracking-[.02em]  hover:text-black/65 font-bold  focus:text-red-500">Programs</button>
                                    </p>
                                </ScrollLink>
                            </li>


                            <li className='  cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <ScrollLink to="course" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-500">Courses</button>
                                    </p>
                                </ScrollLink>

                            </li>
                            <li className=' cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <ScrollLink to="features" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-500">Features</button>
                                    </p>
                                </ScrollLink>

                            </li>
                            <li className=' cursor-pointer duration-300 text-gray-800 font-bold text-[12px] xl:text-[14px]' >

                                <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>
                                    <p className="relative group flex items-center">
                                        <button class=" tracking-[.02em]  hover:text-black/65  focus:text-red-500">Contact</button>
                                    </p>
                                </ScrollLink>
                            </li>

                            {/* <li>
                                <Link to='/contact'>
                                    contact
                                </Link>
                            </li> */}
                        </ul>

                        <div className='cursor-pointer font-[poppins]' >
                            <a href="https://forms.gle/JrJRbwRoBEBb7i4c7" target="_blank">
                                <button className='  flex items-center font-semibold  px-2 py-1  md:px-3.5 md:py-2 uppercase text-[12px] md:text-[16px] rounded-xl shadow-md shadow-red-300  hover:shadow-red-400  border-2 border-white hover:border-gray-100 bg-red-500 hover:bg-red-600 hover:white text-white'>application</button>
                            </a>
                        </div>


                        {/* Mobile Navigation Icon */}
                        <div onClick={handleNav} className='block lg:hidden'>
                            {nav ? <AiOutlineClose className='w-16 sm:w-16 md:w-20 text-gray-900' /> : <AiOutlineMenu className='w-16 sm:w-16 md:w-20 text-gray-900' />}
                        </div>

                        {/* Mobile Navigation Menu */}
                        {
                            hide &&
                            <ul
                                className={
                                    nav
                                        ? 'fixed lg:hidden left-0 top-0 w-[60%] h-[100%] border-r  bg-gray-50 ease-in-out duration-500'
                                        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
                                }
                            >
                                {/* Mobile Logo */}

                                <Link to='/' onClick={topPage} className='w-full' >
                                    <img src={black} className=' w-36 sm:w-40  pt-10  ml-10' alt="" onClick={handleNav} />
                                </Link>



                                {/* Mobile Navigation Items */}

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-700  text-[13px] mt-8' >
                                    <ScrollLink to="services" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Services</button>
                                        </p>
                                    </ScrollLink>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="course" smooth={true} duration={500} onClick={topPage}>

                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Courses</button>
                                        </p>
                                    </ScrollLink>

                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="features" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Features</button>
                                        </p>
                                    </ScrollLink>
                                </li>

                                <li className=' ml-10 cursor-pointer duration-300 text-gray-500  text-[13px] mt-8' >
                                    <ScrollLink to="contact" smooth={true} duration={500} onClick={topPage}>
                                        <p className="relative group flex items-center">
                                            <button class="font-bold tracking-[.02em]  hover:text-white/50  focus:text-red-600" onClick={handleNav}>Contact</button>
                                        </p>
                                    </ScrollLink>
                                </li>


                            </ul>
                        }

                    </div>
                </div>
            </nav>

        </>
    )
}

export default Header