import React from 'react'
import { Link as ScrollLink } from 'react-scroll';
import Slider from "react-slick";
import { Wave } from 'react-animated-text';


//images
import background from '../images/background.jpg'
import one from '../images/banner/one.png'
import banner from '../images/banner/banner_3.png'
import bannerTwo from '../images/banner/banner_2.png'
import bannerOne from '../images/banner/banner_1.png'

import why from '../images/why.png'

import whatsapp from '../images/app.png'

import aiimage from '../images/ai.png'
import three from '../images/infra.png'
import two from '../images/two.png'
import twoOne from '../images/two-3.png'

import direction from '../images/14 craft/direction.png'
import cinematography from '../images/14 craft/cinema.png'
import vfx from '../images/14 craft/vfxandcg.png'
import edit from '../images/14 craft/edit.png'
import music from '../images/14 craft/music.png'
import art from '../images/14 craft/art.png'
import act from '../images/14 craft/acting.png'
import dance from '../images/14 craft/dance.png'
import still from '../images/14 craft/still.png'
import dubbing from '../images/14 craft/dubbing.png'
import script from '../images/14 craft/script.png'
import costime from '../images/14 craft/costime.png'
import makeup from '../images/14 craft/makeup.png'
import stunt from '../images/14 craft/stuent.png'

import path from '../images/way.png'

import dir from '../images/banner/direction.png'
import cinema from '../images/banner/cinematography.png'
import vfxandcg from '../images/banner/vfx.png'
import editing from '../images/banner/editing.png'

import acting from '../images/banner/acting.png'
import color from '../images/banner/di.png'

import award from '../images/features/1.png'
import trip from '../images/features/2.png'
import students from '../images/features/3.png'
import emi from '../images/features/4.png'
import global from '../images/features/5.png'
import ai from '../images/features/6.png'
import certicate from '../images/features/7.png'

import air from '../images/features/8.png'
import large from '../images/features/9.png'
import tha from '../images/features/10.png'
import dub from '../images/features/11.png'
import audio from '../images/features/12.png'
import reg from '../images/features/13.png'

//icons
import { RiWhatsappLine } from "react-icons/ri";
import { AiFillCodepenCircle } from "react-icons/ai";
import ContactForm from '../components/ContactForm';

import { BiWorld } from "react-icons/bi";
import { PiCertificateFill } from "react-icons/pi";
import { FaRupeeSign } from "react-icons/fa";
import { FaGoogleScholar } from "react-icons/fa6";
import { PiFilmReelFill } from "react-icons/pi";
import { FaVideo } from "react-icons/fa6";
import { IoPeople } from "react-icons/io5";

import { TbChairDirector } from "react-icons/tb";
import { IoVideocam } from "react-icons/io5";
import { IoMdFilm } from "react-icons/io";
import { MdFitScreen } from "react-icons/md";
import { FaPeoplePulling } from "react-icons/fa6";
import { IoColorFilter } from "react-icons/io5";

import { MdPlayArrow } from "react-icons/md";
import Form from '../components/Form';


const Home = () => {


    function topPage() {
        window.scroll(0, 0)
    }

    var settings = {
        // dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 4000,
        cssEase: "linear"
    };




    return (
        <>
            <div className='overflow-hidden'>

                {/* <div>
                    <img src={background} className='fixed top-0 h-screen w-full object-cover -z-20 blur-[2px]' alt="" />
                </div> */}



                <div className=' fixed bottom-10 z-30 right-10 '>
                    <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">

                        <div className='h-16 w-16 rounded-full cursor-pointer flex justify-center items-center duration-700  animate-bounce'>
                            <img src={whatsapp} alt="" />
                        </div>
                    </a>
                </div>

                {/* header page */}

                <section>
                    <div className='flex flex-col md:flex-row items-center bg-gray-900 ' id='contact'>

                        <div className='w-full md:w-[70%]'>
                            <div className="slider-container">
                                <Slider {...settings}>

                                    <div className="w-full  relative">
                                        <img src={bannerTwo} className='w-full object-cover ' alt="" />
                                    </div>


                                    <div className="w-full ">
                                        <img src={bannerOne} className='w-full object-cover ' alt="" />
                                    </div>


                                    <div className="w-full ">
                                        <img src={banner} className='w-full object-cover ' alt="" />
                                    </div>
                                </Slider>
                            </div>
                        </div>


                        <div className='w-full px-2 md:w-[30%] mx-auto'>

                            <div class="">

                                {/* <ContactForm /> */}
                                <Form />
                            </div>

                        </div>
                    </div>

                </section>


                {/* ai */}

                {/* <section className='bg-[#f5f5f5] '>
                    <div className=' px-4 w-full md:w-[80%] mx-auto font-[poppins] pt-10 pb-10 md:pt-36 md:pb-28'>

                        <div className='flex flex-col md:flex-row'>

                            <div className='w-full md:w-[50%] '>
                                <div>
                                    <p className='uppercas text-[16px] sm:text-[20px] md:text-[28px] text-gray-700 mb-2 md:mb-4'>Learn at</p>
                                    <h3 className=' text-[34px] sm:text-[40px] md:text-[54px] tracking-[2px] font-semibold text-gray-900'>India's <span>1</span>st  </h3>
                                    <h2 className='text-[38px] md:text-[65px] font-extrabold text-red-500 tracking-[2px] uppercase -mt-1 md:-mt-4'>AI-Integrated </h2>
                                    <p className='text-[38px] font-semibold text-gray-800'>Film Academy</p>
                                </div>
                            </div>

                            <div className='w-full md:w-[50%] flex justify-center items-center '>

                                <img src={aiimage} className='object-cover drop-shadow-lg' alt="" />

                            </div>

                        </div>

                    </div>
                </section> */}


                {/* current course */}

                <div class=" bg-[#fffafa] ">
                    <div class="pt-10 pb-10 md:pt-20 md:pb-16 bottom-0 left-0 right-0 top-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]" id="course">
                        <section className='font-[poppins] flex flex-col justify-center items-center '>




                            <div className='flex justify-center mb-12'>
                                <h3 className='text-[20px] sm:text-[24px] md:text-[34px] font-bold text-gray-900 uppercase text-center'>Shoot A <span className='text-red-600'>Film  <span className='text-gray-900'>&</span> web series</span> while studying ! </h3>
                            </div>

                            <div className='px-4 w-full md:w-[80%] mx-auto flex flex-col md:flex-row items-start justify-between gap-y-5 gap-x-20 '>

                                <div className='w-full md:w-[50%]  '>
                                    <div className='flex flex-col gap-x-2 gap-y-5 '>

                                        <div className='bg-white rounded-2xl  '>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={dir} className='rounded-2xl ' alt="" />
                                            </div>
                                        </div>

                                        <div className='flex items-center gap-x-6 w-full'>
                                            <div className='bg-white rounded-2xl   w-full'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <img src={cinema} className='rounded-2xl ' alt="" />
                                                </div>
                                            </div>

                                            <div className='bg-white rounded-2xl   w-full'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <img src={vfxandcg} className='rounded-2xl ' alt="" />
                                                </div>
                                            </div>

                                        </div>

                                        <div className='bg-white rounded-2xl   w-full'>
                                            <div className='flex flex-col justify-center items-center'>
                                                <img src={editing} className='rounded-2xl ' alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className=' w-full md:w-[50%] flex flex-col justify-end  '>

                                    <div>
                                        <h3 className=' text-[20px] sm:text-[24px] md:text-[30px] text-gray-900  font-bold text-center md:text-start '>1 Year Diploma </h3>
                                        <h3 className=' text-[20px] sm:text-[24px] md:text-[30px] text-red-500 font-bold text-center md:text-start '>Courses </h3>
                                    </div>

                                    <div className='mt-2 md:mt-6' >


                                        <div className='flex items-center gap-x-3 mt-6 md:mt-8'>

                                            <div className=''>
                                                <TbChairDirector className='text-red-500  text-[26px]' />
                                            </div>

                                            <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Screenplay & Direction</p>
                                        </div>

                                        <div className='flex items-center gap-x-3 mt-6 md:mt-8'>

                                            <div className=''>
                                                <IoVideocam className='text-red-500  text-[26px]' />
                                            </div>

                                            <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Cinematography</p>
                                        </div>

                                        <div className='flex items-center gap-x-3 mt-6 md:mt-8'>

                                            <div className=''>
                                                <IoMdFilm className='text-red-500  text-[26px]' />
                                            </div>

                                            <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Film Editing</p>
                                        </div>

                                        <div className='flex items-center gap-x-3 mt-6 md:mt-8'>

                                            <div className=''>
                                                <MdFitScreen className='text-red-500  text-[26px]' />
                                            </div>

                                            <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>VFX</p>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </section>


                        <section className=' pt-10 pb-10 md:pt-20 md:pb-16 font-[poppins] '>

                            <div className=' w-full px-4 md:w-[80%] mx-auto  ml-0 md:ml-[20rem]'>



                                <div className='flex flex-col-reverse md:flex-row w-full '>

                                    <div className='w-full md:w-[50%] mx-auto mt-4 md:mt-0'>

                                        <div className=''>
                                            <h3 className='text-[20px] sm:text-[24px] md:text-[30px] text-center md:text-start text-gray-900 font-bold'>Weekend</h3>
                                            <h3 className='text-[20px] sm:text-[24px] md:text-[30px] text-center md:text-start text-red-500 font-bold'>Courses </h3>
                                        </div>
                                        <div className='mt-6' >
                                            <div className='flex items-center gap-x-3 mt-8'>

                                                <div className=''>
                                                    <FaPeoplePulling className='text-red-500  text-[26px]' />
                                                </div>
                                                <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Acting</p>
                                            </div>

                                            <div className='flex items-center gap-x-3 mt-8'>

                                                <div className=''>
                                                    <IoColorFilter className='text-red-500  text-[26px]' />
                                                </div>
                                                <p className='capitalize text-[12px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Digital Color Grading</p>
                                            </div>


                                        </div>

                                    </div>

                                    <div className='w-full md:w-[50%] mx-auto mt-6 md:mt-0'>

                                        <div className='flex flex-col justify-center items-center gap-x-2 gap-y-5 w-full md:w-[80%]'>

                                            <div className='bg-white rounded-2xl   w-full'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <img src={acting} className='rounded-xl object-cover' alt="" />
                                                </div>
                                            </div>

                                            <div className='bg-white rounded-2xl  w-full'>
                                                <div className='flex flex-col justify-center items-center'>
                                                    <div>
                                                        <img src={color} className='rounded-xl object-cover' alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>


                {/* why cinema factory */}

                <section className=' pt-5 md:pt-10 pb-10 bg-gray-100 font-[poppins]'>

                    <div className='w-full px-3 md:w-[80%] mx-auto flex flex-col md:flex-row justify-center items-center gap-8 gap-x-40'>

                        <div className=' w-full px-4 md:w-[50%] mx-auto '>

                            <img src={why} className='object-cover w-full drop-shadow-xl' alt="" />

                        </div>

                        <div className='w-full px-3 md:w-[50%] mx-auto'>
                            <div className=''>

                                <div>
                                    <h3 className='font-bold text-[18px] sm:text-[18px] md:text-[30px] mb-2 uppercase text-gray-900'>
                                        <Wave text="Why Cinema Factory?" effect="stretch" effectChange={2.0} />
                                    </h3>
                                </div>

                                <div className='mt-5 text-gray-800'>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <BiWorld className='text-red-500 text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Free Overseas Study Trip</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <PiCertificateFill className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Prestigious Certifications</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <FaRupeeSign className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Flexible EMI Facilities</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <FaGoogleScholar className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Internship Opportunities</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <PiFilmReelFill className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Cinema Industry Connectivity</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <FaVideo className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Shoot a Web Series while Studying</p>
                                    </div>

                                    <div className='flex items-center gap-x-3 mt-4'>

                                        <div className=''>
                                            <IoPeople className='text-red-500  text-[24px] md:text-[26px]' />
                                        </div>

                                        <p className='capitalize text-[14px] sm:text-[16px] md:text-[18px] font-semibold'>Award Winning Cinema Experts as Tutors</p>
                                    </div>

                                </div>

                            </div>

                            <div>
                                <a href="https://forms.gle/JrJRbwRoBEBb7i4c7" target="_blank">
                                    <button className='bg-gray-900 w-fit mt-6 text-white font-semibold uppercase px-3 py-1.5 md:px-5 md:py-2.5 rounded-md text-[14px] md:text-[18px]'>Get Started</button>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>

                {/* 14 carfts */}

                <section className='bg-white' id='services'>
                    <div className='px-4 w-full  md:w-[80%] flex items-start mx-auto pt-10 pb-10 md:pt-28 md:pb-10 font-[poppins]'>

                        <div className='flex flex-col md:flex-row gap-y-10 gap-x-16'>

                            <div className='w-full md:w-[40%]' >

                                <div className='flex items-center gap-x-2 md:flex-col justify-center  md:items-start md:justify-start '>
                                    {/* <p className='uppercase mb-2 md:mb-4 text-gray-900'>our best services</p> */}

                                    {/* <h2 className='animate-bounce duration-1000 delay-700 font-bold text-[24px] md:text-[38px] mb-2 capitalize text-gray-900 flip-animation jump-animation'>hub of <span className='font-[roboto] text-[24px] md:text-[38px] text-red-500 font-extrabold '>14 crafts</span></h2> */}

                                    <h2 className='font-bold text-[16px] md:text-[58px] mb-2 '>A Core Hub</h2>
                                    <h2 className='font-bold text-[16px] md:text-[48px] mb-2'>Of</h2>
                                    <h2 className='font-bold text-[16px] md:text-[88px]  text-red-500 animate-bounce duration-1000 delay-700'>14 Crafts</h2>
                                    <h2 className='font-bold text-[16px] md:text-[48px] mb-2'>Of Cinema</h2>



                                    <p className='text-[12px] md:text-[14px] text-gray-800 '></p>

                                    <div className='mt-6'>
                                        <div className="flex flex-col gap-y-6">

                                            {/* 
                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Comprehensive training in directing, from script to screen.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Master camera work, lighting, visual storytelling.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Mastering editing to create seamless, impactful narratives.</p>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Learning the latest in visual effects and computer graphics.</p>
                                            </div> */}

                                            {/* <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Craft characters for film and TV through makeup.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Learn music composition and sound design for cinema.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Training in various dance forms used in films.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Professional stunt performance and coordination training.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Designing and creating film sets and locations.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Techniques for recording and engineering film sound.</p>
                                            </div>

                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Designing and creating character and period costumes.</p>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Crafting compelling stories and scripts for various genres.</p>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Techniques for capturing storytelling still images.</p>
                                            </div>
                                            <div className="flex items-center gap-x-3">
                                                <MdPlayArrow className='text-[20px] text-red-500' />

                                                <p className='text-[12px] md:text-[14px]'>Comprehensive training in screen performance art.</p>
                                            </div> */}


                                        </div>
                                    </div>


                                </div>
                            </div>


                            <div className=' w-full md:w-[60%] flex justify-center items-center'>

                                <div className='grid grid-cols-4  md:gap-x-12 gap-y-6 md:gap-y-10'>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={direction} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Direction</h3>
                                    </div>


                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={cinematography} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Cinematography</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={vfx} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>CG & VFX</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={edit} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Editing</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={music} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Music</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={art} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Art Direction</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={act} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Acting</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={dance} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Dance</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={still} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Photography</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={dubbing} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Dubbing</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={script} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Script Writing</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={costime} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Costume Design</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={makeup} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Makeup</h3>
                                    </div>

                                    <div className='flex flex-col justify-center items-center gap-y-2 sm:gap-y-3 md:gap-y-5 ' >
                                        <div>
                                            <img src={stunt} className='w-16 h-16 md:w-24 md:h-24 object-cover drop-shadow rounded-full ' alt="" />
                                        </div>
                                        <h3 className='text-[10px] sm:text-[12px]  md:text-[14px] font-semibold'>Stunt</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                {/* awards */}

                <section className='bg-[#F8F8FF] pt-10 pb-10 md:pt-16 md:pb-16'>
                    <div className='w-full px-4 md:w-[80%] mx-auto  font-[poppins]'>

                        <div className='flex flex-col md:flex-row items-center gap-x-9'>
                            <div className='w-full md:w-[50%]'>
                                <div className='h-100 w-100 rounded-full p-2'>
                                    <img src={path} className='object-cover drop-shadow-lg w-full' alt="" />
                                </div>
                            </div>

                            <div className='w-full md:w-[50%] mx-auto'>

                                <div>
                                    <h3 className='text-[24px] sm:text-[26px] md:text-[45px] uppercase font-bold mb-5 text-gray-900'>Path To Success</h3>
                                    <p className='text-[14px] sm:text-[14px] md:text-[16px] text-gray-600'>At Cinema Factory Academy, We believe in paving your way to a successful career in the cinematic arts. Our comprehensive program offers unparalleled opportunities to ensure you thrive in the industry</p>


                                    <div className='mt-7'>
                                        <a href="https://api.whatsapp.com/send?phone=9884683888" target="_blank" rel="noopener noreferrer" className="">

                                            <button className='bg-gray-900 text-white shadow-md drop-shadow-md h-10 pl-3 rounded-md flex items-center gap-x-3  font-[poppins] text-[14px] md:text-[16px] hover:scale-105 duration-500'> Download syllabus <div className='px-3 bg-green-600  rounded-r-md'><RiWhatsappLine className='text-white h-10    text-[22px] font-bold' /> </div>  </button>
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </section>


                {/* featrues */}

                <section className='pt-10 pb-10 md:pt-16 md:pb-20 bg-[#BBC8CA] font-[poppins]' id='features'>


                    <div className=' px-4 w-full md:w-[80%]  mx-auto '>

                        <div className='flex justify-start mb-10'>
                            <h3 className='font-bold uppercase  text-black text-[20px] sm:text-[24px] md:text-[30px]'>Prominent Features</h3>
                        </div>

                        <div className='flex flex-col md:flex-row items-center gap-y-8  gap-x-6'>

                            <div className='w-full md:w-[70%]'>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8'>

                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={award} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Award winning Film makers as Regular faculties</h3>
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={trip} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px]  font-semibold text-gray-900'>Overseas Trip</h3>
                                        </div>
                                    </div>

                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={students} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px]  font-semibold text-gray-900'>Students shoot a film/web series while studying</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={emi} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>EMI facilities</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={global} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Global Connectivity</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={ai} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Al integration</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={certicate} className='w-16 md:w-24 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[18px] font-semibold text-gray-900'>Prestigious certification</h3>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className=' w-full md:w-[30%] mx-auto flex justify-center items-center'>

                                <img src={two} className='object-cover w-[50%] md:w-[400rem] drop-shadow-lg ' alt="" />

                                {/* <img src={twoOne} className='flex md:hidden object-cover  w-[80%] md:w-[200rem] drop-shadow-lg ' alt="" /> */}

                            </div>
                        </div>



                    </div>
                </section>


                <section className='  font-[poppins] bg-gray-900 pt-10 pb-10 md:pt-16 md:pb-20'>


                    <div className='w-[80%]  mx-auto '>

                        <div className='flex justify-center mb-10'>
                            <h3 className='font-bold uppercase  text-white text-[20px] sm:text-[24px] md:text-[30px]'>Our Facilities</h3>
                        </div>


                        <div className='flex flex-col-reverse md:flex-row items-center gap-y-8 gap-x-20'>

                            <div className='w-full md:w-[30%] mx-auto flex justify-center items-center'>

                                <img src={three} className='object-cover w-[80%]  md:w-[100rem]' alt="" />

                            </div>

                            <div className=' w-full md:w-[70%] '>
                                <div className='grid grid-col-1 md:grid-cols-2 gap-x-4 gap-y-8'>

                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={air} className='w-16 md:w-20 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>Air conditioned class rooms</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={large} className='w-16 md:w-20 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>Large Professional shooting floors</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={tha} className='w-16 md:w-20 object-cover' alt="" />

                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>DCI equipped Preview theatre for special screenings</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={dub} className='w-16 md:w-20 object-cover' alt="" />

                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>Dubbing Studio</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={audio} className='w-16 md:w-20 object-cover' alt="" />

                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>Audio/Podcast books</h3>
                                        </div>
                                    </div>
                                    <div className='flex items-center gap-x-3'>
                                        <div>
                                            <img src={reg} className='w-16 md:w-20 object-cover' alt="" />
                                        </div>
                                        <div>
                                            <h3 className='capitalize text-[14px] sm:text-[14px] md:text-[16px] font-semibold text-white'>Regular Classes conducted with RED 6k Cinema Cameras</h3>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>
                </section>
            </div>


        </>
    )
}

export default Home